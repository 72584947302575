<template>
    <div id="template-normal" class="p-0">
        <slot></slot>
	</div>
</template>

<script>
	export default {
		name: 'template-normal',
		data() {
			return {
                
			}
		}
	}
</script>


<style lang="scss" scoped>
    #template-normal {
        //padding: 0px;
    }
</style>